import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

// Image imports
import slider1 from "../../assets/images/slider-image/slider-1.jpg";
import slider2 from "../../assets/images/slider-image/slider-2-3.jpg";
import slider3 from "../../assets/images/slider-image/slider-3.jpg";
import slider4 from "../../assets/images/slider-image/slider-4.jpg";
import slider5 from "../../assets/images/slider-image/slider-5.jpg";
import slider6 from "../../assets/images/slider-image/slider-6.jpg";

const HeroSlider = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    swiperRef.current = new Swiper(".hero-slider", {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      effect: "fade",
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    // Cleanup Swiper instance on component unmount
    return () => {
      if (swiperRef.current && swiperRef.current.destroy) {
        swiperRef.current.destroy(true, true);
      }
    };
  }, []);

  const slides = [
    {
      image: slider1,
      title: "Elevate Your Events",
      description: "Add sophistication and style to your events with our premium furniture rentals. Asana Furniture offers curated collections to create unforgettable experiences.",
    },
    {
      image: slider3,
      title: "Celebrate With Style",
      description: "From intimate gatherings to grand celebrations, Asana Furniture provides stylish furniture solutions to elevate every occasion with ease and elegance.",
    },
    {
      image: slider2,
      title: "Elevate Your Events",
      description: "Redefine your event decor with our high-quality furniture rentals. At Asana Furniture, we specialize in making your events stand out in style.",
    },
    {
      image: slider4,
      title: "Elevate Your Events",
      description: "Redefine your event decor with our high-quality furniture rentals. At Asana Furniture, we specialize in making your events stand out in style.",
    },
    {
      image: slider5,
      title: "Elevate Your Events",
      description: "Redefine your event decor with our high-quality furniture rentals. At Asana Furniture, we specialize in making your events stand out in style.",
    },
    {
      image: slider6,
      title: "Elevate Your Events",
      description: "Redefine your event decor with our high-quality furniture rentals. At Asana Furniture, we specialize in making your events stand out in style.",
    },
  ];

  return (
    <div className="section">
      <div className="hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white">
        {/* Slides */}
        <div className="swiper-wrapper">
          {slides.map((slide, index) => (
            <div key={index} className="hero-slide-item slider-height-2 swiper-slide d-flex">
              <div className="hero-bg-image">
                <img src={slide.image} alt={slide.title} />
              </div>
              <div className="container align-self-center">
                <div className="row justify-content-center">
                  <div className="col-md-8 offset-2 align-self-center m-auto">
                    <div className="hero-slide-content hero-slide-content-2 slider-animated-1 text-center">
                      <span className="category"></span>
                      <h2 className="title-1">{slide.title}</h2>
                      <p className="w-100">{slide.description}</p>
                      <Link
                        to="/shop"
                        className="btn btn-lg btn-primary btn-hover-dark mt-5"
                      >
                        Explore Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="swiper-pagination swiper-pagination-white"></div>

        {/* Navigation Buttons */}
        <div className="swiper-buttons">
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlider;
