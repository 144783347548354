// import React, { useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import cat1 from "../../assets/images/icons/sofa.png";
// import cat2 from "../../assets/images/icons/BrideGroomCouches.png";
// import cat3 from "../../assets/images/icons/diningchairs.png";
// import cat4 from "../../assets/images/icons/diningtables.png";
// import cat5 from "../../assets/images/icons/cocktailstablesconsoles.png";
// import cat6 from "../../assets/images/icons/coffee-table.png";
// import cat7 from "../../assets/images/icons/furnitureaccessories.png";
// import Swiper from "swiper/bundle";
// import "swiper/css/bundle";

// const CategorySlider = () => {
//   const swiperRefs = useRef(null);

//   useEffect(() => {
//     // Initialize Swiper when component mounts
//     swiperRefs.current = new Swiper(".category-slider", {
//       slidesPerView: 5, // Adjust as needed
//       spaceBetween: 30,
//       loop: true,
//       autoplay: {
//         delay: 3000,
//         disableOnInteraction: false,
//       },
//       pagination: {
//          el: ".category-slider-pagination",
//         clickable: true,
//       },
//       navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//       },
//       breakpoints: {
//         240: {
//             slidesPerView: 2,
//           },
//         640: {
//           slidesPerView: 2,
//         },
//         768: {
//           slidesPerView: 3,
//         },
//         1024: {
//           slidesPerView: 4,
//         },
//         1200: {
//           slidesPerView: 5,
//         },
//       },
//     });

//     // Cleanup on unmount
//     return () => {
//       if (swiperRefs.current) {
//         swiperRefs.current.destroy();
//       }
//     };
//   }, []);

//   return (
//     <div className="section pt-100px pb-100px">
//       <div className="container">
//         <div className="category-slider swiper-container" data-aos="fade-up">
//           <div className="category-wrapper swiper-wrapper">
//             <div className="swiper-slide">
//               <Link to="/shop" className="category-inner">
//                 <div className="category-single-item">
//                   <img src={cat1} alt="" style={{width:80}}/>
//                   <span className="title">Sofa</span>
//                 </div>
//               </Link>
//             </div>
//             <div className="swiper-slide">
//               <Link to="/shop" className="category-inner">
//                 <div className="category-single-item">
//                   <img src={cat2} alt="" style={{width:80}}/>
//                   <span className="title">Bride & Groom <br></br>Couches</span>
//                 </div>
//               </Link>
//             </div>
//             <div className="swiper-slide">
//               <Link to="/shop" className="category-inner">
//                 <div className="category-single-item">
//                   <img src={cat3} alt="" style={{width:80}}/>
//                   <span className="title">Dining Chairs</span>
//                 </div>
//               </Link>
//             </div>
//             <div className="swiper-slide">
//               <Link to="/shop" className="category-inner">
//                 <div className="category-single-item">
//                   <img src={cat4} alt="" style={{width:80}}/>
//                   <span className="title">Dining Tables</span>
//                 </div>
//               </Link>
//             </div>
//             <div className="swiper-slide">
//               <Link to="/shop" className="category-inner">
//                 <div className="category-single-item">
//                   <img src={cat5} alt="" style={{width:80}}/>
//                   <span className="title">Cocktails Tables & <br></br>Consoles</span>
//                 </div>
//               </Link>
//             </div>
//             <div className="swiper-slide">
//               <Link to="/shop" className="category-inner">
//                 <div className="category-single-item">
//                   <img src={cat6} alt="" style={{width:80}}/>
//                   <span className="title">Coffee & Side <br></br>Tables</span>
//                 </div>
//               </Link>
//             </div>
//             <div className="swiper-slide">
//               <Link to="/shop" className="category-inner">
//                 <div className="category-single-item">
//                   <img src={cat7} alt="" style={{width:80}}/>
//                   <span className="title">Accessories</span>
//                 </div>
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CategorySlider;



import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { GetApiHandlerWithoutParams } from "../../utils/Api";
import cat1 from "../../assets/images/icons/sofa.png";

const CategorySlider = () => {
  const [categories, setCategories] = useState([]);
  const swiperRefs = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch categories from the API
    const fetchCategories = async () => {
      try {
        const response = await GetApiHandlerWithoutParams("category");
        if (response.success && response.addresses) {
          setCategories(response.addresses);
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    // Initialize Swiper
    swiperRefs.current = new Swiper(".category-slider", {
      slidesPerView: 5,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".category-slider-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        240: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
    });

    // Cleanup Swiper
    return () => {
      if (swiperRefs.current) {
        swiperRefs.current.destroy();
      }
    };
  }, []);

  const handleCategoryClick = (categoryId) => {
    navigate(`/shop?category_id=${categoryId}`);
  };

  return (
    <div className="section pt-100px pb-100px">
      <div className="container">
        <div className="category-slider swiper-container" data-aos="fade-up">
          <div className="category-wrapper swiper-wrapper">
            {categories.map((category) => (
              <div
                className="swiper-slide"
                key={category.id}
                onClick={() => handleCategoryClick(category.id)}
              >
                <div className="category-inner">
                  <div className="category-single-item">
                    <img
                      src={category.image || cat1}
                      alt={category.category_name}
                      style={{ width: 80 }}
                    />
                    <span className="title" style={{width:130,cursor:'pointer'}}>{category.category_name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorySlider;
