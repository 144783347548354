import React from "react";
import { Link } from "react-router-dom";

export default function Emptycart() {
return<>
<div className="empty-cart-area pb-100px pt-100px">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="cart-heading">
                        {/* <h2>Your cart item</h2> */}
                    </div>
                    <div className="empty-text-contant text-center">
                        <i className="icon-handbag"></i>
                        <h1>There are no more items in your cart</h1>
                        <Link className="empty-cart-btn" to="/shop">
                            <i className="ion-ios-arrow-left"> </i> Continue shopping
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
}