import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import HeroSlider from "../Components/Slider/HeroSlider";
import CategorySlider from "../Components/Slider/CategorySlider";
import Instagram from "./Instagram";
import { GetApiHandler } from "../utils/Api";

import banner1 from "../assets/images/banner/homebanner7.jpg";
import banner2 from "../assets/images/banner/homebanner6.jpg";
import p1 from "../assets/images/product-image/1.jpg"; // Placeholder image
import IsToken from "../utils/Handeler/IsToken";
import ImageBoxSlider from "../Components/Slider/ImageBoxSlider";

// Helper function to format date as YYYY-MM-DD
const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export default function Home() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date()); // State for rental start date as Date object
  const [endDate, setEndDate] = useState(new Date()); // State for rental end date as Date object

  const navigate = useNavigate(); // Hook for navigation
  useEffect(() => {
    fetchInventories();
  }, []);

  // const fetchInventories = async () => {
  //   try {
  //     const params = {
  //       rentalstartdate: formatDate(startDate), // Format before sending
  //       rentalenddate: formatDate(endDate),
  //     };
  //     const endPoint = `inventories`;
  //     const result = await GetApiHandler(params, endPoint);
  //     console.log("result", result);
  //     if (
  //       result &&
  //       result.success &&
  //       result.inventories &&
  //       result.inventories.data
  //     ) {
  //       // Get the latest 8 products
  //       const latestProducts = result.inventories.data
  //         .slice(0, 8)
  //         .map((inventory) => ({
  //           id: inventory.id,
  //           name: inventory.product.product_name,
  //           price: inventory.product.rental_price,
  //           image: inventory.product.image_1 || p1, // Use placeholder image if not available
  //           badge: inventory.product_tags.length
  //             ? inventory.product_tags[0]
  //             : null,
  //           off: inventory.product.discount_percentage,
  //           link: `/product/${inventory.product.id}`,
  //           location: inventory.location.name, // Add location as a badge or info
  //         }));
  //       setProducts(latestProducts);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch products:", error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  
  const fetchInventories = async () => {
    try {
      const params = {
        rentalstartdate: formatDate(startDate), // Format before sending
        rentalenddate: formatDate(endDate),
      };
      const endPoint = `inventories`;
      const result = await GetApiHandler(params, endPoint);
      console.log("result", result);
  
      if (
        result &&
        result.success &&
        result.inventories &&
        result.inventories.data
      ) {
        // Filter and map inventories with valid product data
        const latestProducts = result.inventories.data
          .filter((inventory) => inventory.product) // Ensure inventory has a product
          .slice(0, 8)
          .map((inventory) => ({
            id: inventory.id,
            name: inventory.product.product_name,
            price: inventory.product.rental_price,
            image: inventory.product.image_1 || p1, // Use placeholder image if not available
            badge: inventory.product_tags.length
              ? inventory.product_tags[0]
              : null,
            off: inventory.product.discount_percentage,
            link: `/product/${inventory.product.id}`,
            location: inventory.location.name, // Add location as a badge or info
          }));
  
        setProducts(latestProducts);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleProductClick = (id) => {
    // Navigate to the SingleProduct component and pass the product id in the URL
    navigate(`/product/${id}`);
  };

  return (
    <>
      {/* Hero/Intro Slider Start */}
      <HeroSlider />
      {/* Hero/Intro Slider End */}

      {/* Product Category Start */}
      <CategorySlider />
      {/* Product Category End */}


      {/* YouTube Auto-Play Video Section 111111111111 change    new new bbbbbbbyyuikolpppppppp*/}
      <div className="section pb-100px">
  <div className="container">
    <div className="row" style={{ justifyContent: "center" }}>
      <div className="col-md-3 text-center" data-aos="fade-up">
        <div className="video-wrapper">
          <video
            width="100%"
            height="450"
            autoplay="autoplay"
            muted
            loop
            playsInline
            preload="auto"
          >
            <source
              src="https://asana-production.nyc3.digitaloceanspaces.com/videos/videoplayback%20(2).mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      

      <div className="col-md-3 text-center" data-aos="fade-up">
        <div className="video-wrapper">
          <video
            width="100%"
            height="450"
            autoplay="autoplay"
            muted
            loop
            playsInline
            preload="auto"
          >
            <source
              src="https://asana-production.nyc3.digitaloceanspaces.com/videos/videoplayback%20(1).mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      

      <div className="col-md-3 text-center" data-aos="fade-up">
        <div className="video-wrapper">
          <video
            width="100%"
            height="450"
            autoplay="autoplay"
            muted
            loop
            playsInline
            preload="auto"
          >
            <source
              src="https://asana-production.nyc3.digitaloceanspaces.com/videos/videoplayback.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
</div>


      {/* Latest Products Section */}
      <div className="section product-tab-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center" data-aos="fade-up">
              <div className="section-title mb-0">
                <h2 className="title">New Arrivals</h2>
                <p className="sub-title mb-30px">
                New Arrivals That Redefine Elegance and Functionality.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              {loading ? (
                <p>Loading products...</p>
              ) : (
                <div className="row">
                  {products.map((product) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-30px"
                      key={product.id}
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="product">
                        <div className="thumb">
                          <Link
                            to="#"
                            className="image"
                            onClick={() => handleProductClick(product.id)}
                          >
                            <img src={product.image} alt={product.name} />
                            <img
                              className="hover-image"
                              src={product.image}
                              alt={product.name}
                            />
                          </Link>
                          <span className="badges">
                            {product.off && (
                              <span className="sale">{product.off}% off</span>
                            )}
                            <span className="new">{product.badge}</span>
                          </span>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <Link to={product.link}>{product.name}</Link>
                          </h5>
                          {IsToken() ? (
                            <span className="price">
                              <span className="new">₹{product.price}</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Latest Products Section End */}

      {/* Banner Section Start */}
      <div className="section pb-100px pt-100px">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-12 mb-md-30px mb-lm-30px"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <a href="/shop" className="banner">
                <img src={banner1} alt="" />
                {/* <div className="info justify-content-end">
                  <div className="content align-self-center">
                    <h3 className="title" style={{ color: "#fff" }}>
                      Sale Furniture <br /> For Summer
                    </h3>
                    <p style={{ color: "#fff" }}>Shop Now</p>
                  </div>
                </div> */}
              </a>
            </div>

            <div
              className="col-lg-6 col-12"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <a href="/shop" className="banner">
                <img src={banner2} alt="" />
                {/* <div className="info justify-content-start">
                  <div className="content align-self-center">
                    <h3 className="title">
                      Office Chair <br /> For Best Offer
                    </h3>
                    <p>Shop Now</p>
                  </div>
                </div> */}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Banner Section End */}


      {/* Instagram Area Start */}
      <div className="section pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-md-12" data-aos="fade-up">
              <div className="section-title text-center mb-11">
                <h2 className="title">Stay Inspired</h2>
                <p className="sub-title">
                Follow Us on Instagram for the Latest Trends!
                </p>
              </div>
              <ImageBoxSlider/>
            </div>
          </div>
        </div>
      </div>
      {/* Instagram Area End */}

      {/* <Instagram /> */}
    </>
  );
}
