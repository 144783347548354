import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import image1 from "../../assets/images/banner/image1.jpg";
import image2 from "../../assets/images/banner/image2.jpg";
import image3 from "../../assets/images/banner/image3.jpg";
import image4 from "../../assets/images/banner/image4.jpg";
import image5 from "../../assets/images/banner/image5.jpg";

import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const ImageBoxSlider = () => {
  const swiperRefs = useRef(null);

  useEffect(() => {
    // Initialize Swiper when component mounts
    swiperRefs.current = new Swiper(".image-box-slider", {
      slidesPerView: 5, // Adjust as needed
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      breakpoints: {
        240: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });

    // Cleanup on unmount
    return () => {
      if (swiperRefs.current) {
        swiperRefs.current.destroy();
      }
    };
  }, []);

  const images = [image1, image2, image3, image4, image5];

  return (
    <div className="section">
      <div className="container">
        <div className="image-box-slider swiper-container" data-aos="fade-up">
          <div className="swiper-wrapper">
            {images.map((img, index) => (
              <div key={index} className="swiper-slide">
                <Link to="/shop" className="category-inner">
                  <div className="image-box">
                    <img src={img} alt={`Slide ${index + 1}`} />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageBoxSlider;
