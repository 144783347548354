import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import { GetApiHandlerWithoutParams, GetApiHandler } from "../utils/Api";
import p1 from "../assets/images/product-image/1.jpg";
import IsToken from "../utils/Handeler/IsToken";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";

// Helper function to format date as YYYY-MM-DD
const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export default function Shop() {
  const [sortCriteria, setSortCriteria] = useState("Relevance");
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]); 
  const [selectedLocation, setSelectedLocation] = useState({ id: "", name: "" });
  const [startDate, setStartDate] = useState(new Date()); // State for rental start date as Date object
  const [endDate, setEndDate] = useState(new Date()); // State for rental end date as Date object
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(12); // Number of items per page

  const navigate = useNavigate(); 

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get("category_id");


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  
  useEffect(() => {
    if (categoryId) {
      inventoriesApiCall(1, limit, categoryId);
    } else {
      inventoriesApiCall(1, limit);
    }
  }, [categoryId, limit]);

  // Fetch inventories and locations when component mounts or filters change
  useEffect(() => {
    locationsApiCall(); // Fetch locations initially
  }, []);

  useEffect(() => {
    if (!categoryId) {
      inventoriesApiCall(); 
    }
  }, [selectedLocation, startDate, endDate]);

  const locationsApiCall = async () => {
    try {
      const endPoint = `location`;
      const result = await GetApiHandlerWithoutParams(endPoint, "");
      console.log("Location", result);
      if (result && result.success && result.addresses) {
        const formattedData = result.addresses.map((location) => ({
          value: location.id,
          label: location.name,
        }));
        setLocations(formattedData); 
      }
    } catch (err) {
      console.error("Failed to fetch locations:", err.message);
    }
  };

  const inventoriesApiCall = async (page = 1, limitValue = limit) => {
    try {
      const params = {
        location_id: selectedLocation.id,
        rentalstartdate: formatDate(startDate),
        rentalenddate: formatDate(endDate),
        page,
        limit: limitValue,
        // category_id: categoryId,
      };
      
      if (categoryId) {
        params.category_id = categoryId; // Include category_id only if it's provided
      }

      const endPoint = `inventories`;
      const result = await GetApiHandler(params, endPoint);
      console.log("result", result);
  
      if (result && result.success && result.inventories && result.inventories.data) {
        // const fetchedProducts = result.inventories.data.map((inventory) => ({
        //   id: inventory.id,
        //   name: inventory.product.product_name,
        //   price: inventory.product.rental_price,
        //   image: inventory.product.image_1 || p1,
        //   badge: inventory.location.name,
        //   off: inventory.product.discount_percentage,
        //   quantity: inventory.available_quantity,
        //   link: `/product/${inventory.product.id}`,
        // }));

        const fetchedProducts = result?.inventories?.data
  ?.filter((inventory) => inventory?.product) // Ensure inventory has a product
  .map((inventory) => ({
    id: inventory.id,
    name: inventory.product.product_name,
    price: inventory.product.rental_price,
    image: inventory.product.image_1 || p1,
    badge: inventory.location.name,
    off: inventory.product.discount_percentage,
    quantity: inventory.available_quantity,
    link: `/product/${inventory.product.id}`,
  }));

  
        setProducts(fetchedProducts);
        setCurrentPage(result.inventories.current_page);
        setTotalPages(result.inventories.last_page);
      }
    } catch (err) {
      console.error("Failed to fetch inventories:", err.message);
    }
  };
  
  // Adjust handleNextPage and handlePrevPage functions to use limit
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      inventoriesApiCall(currentPage + 1, limit);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      inventoriesApiCall(currentPage - 1, limit);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    inventoriesApiCall(1, parseInt(e.target.value, 10)); // Fetch first page with the new limit
  };

  const handleLocationChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const locationName = event.target.options[selectedIndex].text;

    setSelectedLocation({
      id: event.target.value,
      name: locationName,
    });
  };

  return (
    <>
      <div className="shop-category-area pb-100px pt-70px">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
            

              <div className="shop-bottom-area">
                <div className="row">
                  {products.map((product) => (
                    <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-xs-6" data-aos="fade-up" data-aos-delay="200" key={product.id}>
                      <div className="product mb-25px">
                        <div className="thumb">
                          <Link to={`/product/${product.id}`} className="image">
                            <img src={product.image} alt={product.name} />
                            <img className="hover-image" src={product.image} alt={product.name} />
                          </Link>
                          <span className="badges">
                            {product.off && <span className="sale">{product.off}% off</span>}
                            {/* <span className="new">{product.badge}</span> */}
                          </span>
                        </div>
                        <div className="content">
                          <h5 className="title">
                            <Link to={`/product/${product.id}`}>{product.name}</Link>
                            {/* <p>{product.quantity}</p> */}
                          </h5>
                          <span className="price">
                            {IsToken() ? <span className="new">₹{product.price}</span> : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>


            <div className="pagination-controls d-flex justify-content-center align-items-center mt-4">
  <button
    onClick={handlePrevPage}
    disabled={currentPage === 1}
    className="pagination-button"
  >
    &laquo; Previous
  </button>
  <div className="page-info">
    Page <strong>{currentPage}</strong> of <strong>{totalPages}</strong>
  </div>
  <button
    onClick={handleNextPage}
    disabled={currentPage === totalPages}
    className="pagination-button"
  >
    Next &raquo;
  </button>
  
</div>



          </div>
        </div>
      </div>
    </>
  );
}
