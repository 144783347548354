const apiBaseURL = "https://apiasana.asanafurniture.com/api/v1";

// const apiBaseURL = "https://apiasana.innosites.in/api/v1";


const isToken = () =>{
  const user = JSON.parse(localStorage.getItem("user"));

  const token = user ? user.token : null;
  if (token && token !== null && token !== undefined && token !== "") {
    return token;
  } else {
    return "";
  }
}

export const loginUser = async (mobile_or_id, password) => {
  const requestBody = { mobile_or_id, password };
  console.log('Sending data to API:', requestBody);

  const response = await fetch(`${apiBaseURL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
    },
    body: JSON.stringify({ mobile_or_id, password }),
  });

  const data = await response.json();
  // console.log(data);
  return data;
};


//register

export const RegisterPostApiHandler = async (formData, endPoint) => {
  console.log('RegisterPostApiHandler called with:', formData, endPoint);
  try {
      const response = await fetch(`${apiBaseURL}/${endPoint}`, {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          console.log('Response not OK:', response.status);
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json().catch(() => {
          throw new Error('Invalid JSON response');
      });
      console.log('API Response:', data);
      return data;
  } catch (error) {
      console.error("API call error:", error.message);
      return { success: false, message: error.message };
  }
};

//logout Api

export const logoutUser = async () => {
  const response = await fetch(`${apiBaseURL}/logout`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${isToken()}`,
    },
  });

  const data = await response.json();
  return data;
};

//location,All inventory,

export const GetApiHandlerWithoutParams = async (endPoint) => {
  const response = await fetch(`${apiBaseURL}/${endPoint}`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${isToken()}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  });
  // Check if the response is successful
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  // Parse JSON response
  const data = await response.json();
  return data;
};

//Get Api

export const GetApiHandler = async (params, endPoint) => {
  // Create query string from params
  const queryString = new URLSearchParams(params)?.toString();

  // Fetch data with GET request
  const response = await fetch(`${apiBaseURL}/${endPoint}?${queryString}`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${isToken()}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  });

  // Check if the response is successful
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Parse JSON response
  const data = await response.json();
  return data;
};


export const PostApiHandler = async (obj, endPoint) => {
  const formData = new FormData();

  // Append all the fields from the obj to the FormData
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  try {
    const response = await fetch(`${apiBaseURL}/${endPoint}`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${isToken()}`, // Ensure token is valid
      },
      body: formData, // Send formData for the request body
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error("API call failed:", error.message);
    throw error; // Re-throw the error to handle it in the calling function
  }
};


export const UpdateUserApiHandler = async (data, endPoint) => {
  const token = isToken(); // Ensure token retrieval

  if (!token) {
    throw new Error("No token provided");
  }

  const formData = new FormData();

  // Append all the fields from the data object to the FormData
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }

  try {
    const response = await fetch(`${apiBaseURL}/${endPoint}`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const result = await response.json();
    return result;

  } catch (error) {
    console.error("API call failed:", error.message);
    throw error;
  }
};


export const PostApiHandlerwithParams = async (obj, endPoint) => {
  const formData = new FormData();

  // Append all the fields from the obj to the FormData
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      formData.append(key, obj[key]);
    }
  }

  try {
    const response = await fetch(`${apiBaseURL}/${endPoint}`, {
      method: 'POST',
      body: formData, // Send formData for the request body
    });

    // Check if the response is OK (status 200-299)
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    // Parse and return the response
    const data = await response.json();
    return data;

  } catch (error) {
    console.error("API call failed:", error.message);
    // Re-throw the error to handle it in the calling function
    throw new Error(`API request failed: ${error.message}`);
  }
};


export const GetOrdersApiHandler = async (params, endPoint) => {
  let queryString = '';
  
  // If params are provided, create query string
  if (params && Object.keys(params).length > 0) {
    queryString = new URLSearchParams(params).toString();
  }

  // Construct the full URL
  const url = queryString ? `${apiBaseURL}/${endPoint}&${queryString}` : `${apiBaseURL}/${endPoint}`;

  try {
    // Fetch data with GET request
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${isToken()}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    });

    // Check if the response is successful
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse JSON response
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in GetApiHandler:", error.message);
    throw error;
  }
};
