import React, { useState, useEffect } from "react";
import Header from "./Components/Header/Header";
import { Outlet, useNavigation } from "react-router-dom"; // useNavigation hook from react-router
import Footer from "./Components/Footer/Footer";
import { GetApiHandlerWithoutParams } from "./utils/Api";
import IsToken from "./utils/Handeler/IsToken";
import { Circles } from "react-loader-spinner"; // Use a spinner from react-loader-spinner

function Layout() {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true); // State for managing loading spinner
  const [locationAndDates, setLocationAndDates] = useState(() => {
    const saved = localStorage.getItem("locationDateFilter");
    return saved ? JSON.parse(saved) : {};
  });

  const navigation = useNavigation(); // Hook to track route navigation state

  const fetchCartItems = async () => {
    if (IsToken()) {
      try {
        const cartEndPoint = `carts`;
        const result = await GetApiHandlerWithoutParams(cartEndPoint, "");
        if (result && result.success && Array.isArray(result.cart)) {
          setCartItems(result.cart);
        } else {
          setCartItems([]);
        }
      } catch (error) {
        console.error("Failed to fetch cart items:", error.message);
        setCartItems([]);
      }
    } else {
      setCartItems([]);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  useEffect(() => {
    if (navigation.state === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [navigation]);

  return (
    <>
      {/* Loading Spinner */}
      {loading && (
        <div className="loading-overlay">
          <Circles
            height="80"
            width="80"
            color="#3498db"
            ariaLabel="loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)"
            }}
          />
        </div>
      )}

      {/* Main Content */}
      {!loading && (
        <>
          <Header
            cartItems={cartItems}
            locationAndDates={locationAndDates}
            setLocationAndDates={setLocationAndDates}
          />
          <Outlet
            context={{
              fetchCartItems,
              locationAndDates,
              setLocationAndDates,
            }}
          />
          <Footer />
        </>
      )}
    </>
  );
}

export default Layout;
