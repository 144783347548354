import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToast = (message = "", type = 'default') => {
    switch (type) {
        case 'success':
            toast.success(message, {
                transition: Bounce
            });
            break;
        case 'error':
            toast.error(message, {
                transition: Bounce
            });
            break;
        case 'info':
            toast.info(message, {
                transition: Bounce
            });
            break;
        case 'warning':
            toast.warn(message, {
                transition: Bounce
            });
            break;
        default:
            toast(message, {
                transition: Bounce
            });
            break;
    }
};

export default CustomToast;
