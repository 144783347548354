import React, { useEffect, useState } from "react";
import { GetApiHandlerWithoutParams } from "../../utils/Api";

export default function LocationDateFilterModal({ show, onClose, onSubmit }) {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({
    id: "",
    name: "",
  });
  const [eventDate, setEventDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isMoreThanADay, setIsMoreThanADay] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    locations: "",
    dates: "",
    timeSlots: "",
  });

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (show) {
      locationsApiCall(); // Fetch locations when the modal is shown
    }
  }, [show]);

  const locationsApiCall = async () => {
    try {
      const endPoint = `location`;
      const result = await GetApiHandlerWithoutParams(endPoint, "");
      if (result && result.success && result.addresses) {
        const formattedData = result.addresses.map((location) => ({
          value: location.id,
          label: location.name,
        }));
        setLocations(formattedData);
      }
    } catch (err) {
      console.error("Failed to fetch locations:", err.message);
    }
  };

  const handleLocationChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const locationName = event.target.options[selectedIndex].text;

    setSelectedLocation({
      id: event.target.value,
      name: locationName,
    });
  };

  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  // Automatically set the minimum end date to the next day after the start date
  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);

    if (isMoreThanADay) {
      setEndDate(""); // Reset the end date when start date changes
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  };

  const handleLocationDateSubmit = () => {
    let valid = true;
    let errors = { locations: "", dates: "", timeSlots: "" };

    if (!selectedLocation.id) {
      errors.locations = "Please select a location.";
      valid = false;
    }

    if (!eventDate && !isMoreThanADay) {
      errors.dates = "Please select the event date.";
      valid = false;
    }

    if (isMoreThanADay && (!startDate || !endDate)) {
      errors.dates = "Please select both start and end dates.";
      valid = false;
    }

    // Time slot is only required if "More than a day" is not selected
    if (!isMoreThanADay && !selectedTimeSlot) {
      errors.timeSlots = "Please select a time slot.";
      valid = false;
    }

    setErrorMessage(errors);

    if (valid) {
      const formData = {
        locationId: selectedLocation.id,
        locationName: selectedLocation.name,
        eventDate,
        startDate: isMoreThanADay ? startDate : eventDate,
        endDate: isMoreThanADay ? endDate : eventDate,
        timeSlot: isMoreThanADay ? null : selectedTimeSlot, // Do not pass time slot if more than a day
      };

      onSubmit(formData);
      onClose(); // Close the modal
    }
  };

  if (!show) return null;

  // Safely calculate the minimum end date
  const getMinEndDate = () => {
    if (!startDate) return today; // Default to today if start date is not selected
    const nextDay = new Date(startDate);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0];
  };

  return (
    <div className="modern-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Select Location and Date</h5>
          <button type="button" className="close-btn" onClick={onClose}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form>
            <div className="form-group">
              <label>Location</label>
              <select
                className="modern-select"
                value={selectedLocation.id}
                onChange={handleLocationChange}
              >
                <option value="">Select a location</option>
                {locations.map((location) => (
                  <option key={location.value} value={location.value}>
                    {location.label}
                  </option>
                ))}
              </select>
              {errorMessage.locations && (
                <small className="error-message">
                  {errorMessage.locations}
                </small>
              )}
            </div>

            <div className="form-group">
              <label>Event Date</label>
              <input
                type="date"
                className="modern-input"
                value={eventDate}
                onChange={(e) => setEventDate(e.target.value)}
                min={today}
                disabled={isMoreThanADay}
              />
              {errorMessage.dates && !isMoreThanADay && (
                <small className="error-message">{errorMessage.dates}</small>
              )}
            </div>

            <div className="form-group">
              <label>Time Slot</label>
              <div className="time-slot-group">
                <label className="radio-label">
                  <input
                    type="radio"
                    name="timeSlot"
                    value="morning"
                    onChange={handleTimeSlotChange}
                    checked={selectedTimeSlot === "morning"}
                  />
                  <span>Morning (10 AM - 4 PM)</span>
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="timeSlot"
                    value="evening"
                    onChange={handleTimeSlotChange}
                    checked={selectedTimeSlot === "evening"}
                  />
                  <span>Evening (4 PM - Midnight)</span>
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="timeSlot"
                    value="fullday"
                    onChange={handleTimeSlotChange}
                    checked={selectedTimeSlot === "fullday"}
                  />
                  <span>Full Day</span>
                </label>
              </div>
              {errorMessage.timeSlots && (
                <small className="error-message">
                  {errorMessage.timeSlots}
                </small>
              )}
            </div>

            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={isMoreThanADay}
                onChange={() => setIsMoreThanADay(!isMoreThanADay)}
                id="moreThanADay"
              />
              <label className="form-check-label" htmlFor="moreThanADay">
                *More than a day
              </label>
            </div>

            {isMoreThanADay && (
              <div className="more-than-day-dates">
                <div className="form-group">
                  <label>Rental Start Date</label>
                  <input
                    type="date"
                    className="modern-input"
                    value={startDate}
                    onChange={handleStartDateChange}
                    min={today}
                  />
                </div>

                <div className="form-group">
                  <label>Rental End Date</label>
                  <input
                    type="date"
                    className="modern-input"
                    value={endDate}
                    onChange={handleEndDateChange}
                    min={getMinEndDate()} // End date must be after the start date
                  />
                  {startDate && endDate < startDate && (
                    <small className="error-message">
                      Rental End Date must be after the Start Date.
                    </small>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="modern-btn secondary"
            onClick={onClose}
          >
            Close
          </button>
          <button
            type="button"
            className="modern-btn primary"
            onClick={handleLocationDateSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
