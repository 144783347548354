import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UpdateUserApiHandler, logoutUser } from "../utils/Api";
import IsToken from "../utils/Handeler/IsToken";
import Sidebar from "../Components/Sidebar/Sidebar";
import "../assets/css/orderhistory.css";
import silver from "../assets/images/banner/silver.png";
import gold from "../assets/images/banner/gold.png";
import platinum from "../assets/images/banner/platinum.png";

export default function ChangePassword() {
  const navigateTo = useNavigate();

  // Retrieve user details from localStorage
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : null;
  const UserTypeTier = user ? user.user_type_tier : null;
  const DiscountPercentage = user ? user.discount_percentage || 0 : 0;
  // console.log(UserTypeTier);
  // console.log(DiscountPercentage);

  // Initialize state with user details or empty strings
  const [name, setName] = useState(user ? user.name : "");
  const [phonenumber, setPhonenumber] = useState(user ? user.mobile : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [companyname, setcompanyname] = useState(user ? user.company_name : "");
  const [companyaddress, setcompanyaddress] = useState(
    user ? user.company_address : ""
  );
  const [city, setcity] = useState(user ? user.city : "");
  const [pincode, setpincode] = useState(user ? user.pincode : "");
  const [gstnumber, setgstnumber] = useState(user ? user.gst_number : "");
  const [sizeofeventcompany, setsizeofeventcompany] = useState(
    user ? user.size_of_event_company : ""
  );
  const [state, setstate] = useState(user ? user.state : "");

  // State for managing success and error messages
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  // State for Password Change
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Validate passwords before making the request
    if (!oldPassword) {
      setErrorMessage((prev) => ({
        ...prev,
        oldPassword: "Old password is required",
      }));
      return;
    }
    if (!newPassword) {
      setErrorMessage((prev) => ({
        ...prev,
        newPassword: "New password is required",
      }));
      return;
    }

    // Prepare the data as an object
    const passwordData = {
      old_password: oldPassword,
      new_password: newPassword,
    };

      const handleLogout = async () => {
        if (IsToken()) {
          try {
            const data = await logoutUser(token);
            if (data.success) {
              localStorage.removeItem("user");
              sessionStorage.removeItem("user");
    
              navigateTo("/login");
            } else {
              alert("Logout failed");
            }
          } catch (error) {
            console.error("Logout error:", error);
            alert("An error occurred. Please try again.");
          }
        } else {
          alert("No user is currently logged in.");
        }
      };

    try {
      const response = await UpdateUserApiHandler(
        passwordData,
        "change-password"
      );
      if (response.success) {
        setMessageType("success");
        setMessage("Password updated successfully.");
        setOldPassword(""); // Clear input fields
        setNewPassword(""); // Clear input fields
        alert("Password updated successfully.");
        handleLogout();
      } else {
        setMessageType("error");
        setMessage(response.message || "Failed to update the password.");
        alert(response.message);
      }
    } catch (error) {
      console.error("Password change failed:", error);
      setMessageType("error");
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="order-history-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <Sidebar />
            </div>

            <div className="col-lg-10 col-md-12">
              <div className="order-content">
                <div className="checkout-wrapper">
                  <div id="faq" className="panel-group">
                    <div
                      className="panel panel-default single-my-account"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="panel-heading my-account-title">
                        <h3 className="panel-title">
                          <span>1 .</span>{" "}
                          <Link
                            data-bs-toggle="collapse"
                            className="collapsed"
                            aria-expanded="true"
                            to="#my-account-1"
                          >
                            Change your password{" "}
                          </Link>
                        </h3>
                      </div>

                      <div
                        id="my-account-1"
                        className="panel-collapse collapse show"
                        data-bs-parent="#faq"
                      >
                          <div className="panel-body">
                          <div className="myaccount-info-wrapper">
                            <form onSubmit={handlePasswordChange}>
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  <div className="billing-info">
                                    <label>Old Password</label>
                                    <input
                                      type="text"
                                      value={oldPassword}
                                      onChange={(e) =>
                                        setOldPassword(e.target.value)
                                      }
                                    />
                                    {errorMessage.oldPassword && (
                                      <span className="error">
                                        {errorMessage.oldPassword}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="billing-info">
                                    <label>New Password</label>
                                    <input
                                      type="text"
                                      value={newPassword}
                                      onChange={(e) =>
                                        setNewPassword(e.target.value)
                                      }
                                    />
                                    {errorMessage.newPassword && (
                                      <span className="error">
                                        {errorMessage.newPassword}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="billing-back-btn">
                                <div className="billing-btn">
                                  <button type="submit">Update Password</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Additional sections for changing password or other actions */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
