import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "./assets/css/plugins/plugins.css";
import "./assets/css/vendor/vendor.min.css";
import "./assets/css/style.css";

import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Layout.js";
import Home from "./Screens/Home.js";
import Login from "./Screens/Login.js";
import Register from "./Screens/Register.js";
import MyAccount from "./Screens/MyAccount.js";
import Cart from "./Screens/Cart.js"; // Assuming you have a Cart component
import PrivateRoute from "./Components/PrivateRoute"; // Assuming this is where your PrivateRoute is saved
import Shop from "./Screens/Shop.js";
import SingleProduct from "./Screens/SingleProduct.js";
import Checkout from "./Screens/Checkout.js";
import OrderSuccess from "./Screens/OrderSuccess.js";
import { Bounce, ToastContainer } from "react-toastify";
import Orderhistory from "./Screens/Orderhistory.js";
import OrderDetails from "./Screens/OrderDetails.js";
import ChangePassword from "./Screens/ChangePassword.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/shop",
        element: <Shop/>,
      },
      {
        path: "/product/:id", 
        element: <SingleProduct/>,
      },
      // {
      //   path: "/cart",
      //   element: <Cart/>,
      // },
      {
        path: "/my-account",
        element: (
          <PrivateRoute>
            <MyAccount />
          </PrivateRoute>
        ),
      },
      {
        path: "/change-password",
        element: (
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        ),
      },
      {
        path: "/cart",
        element: (
          <PrivateRoute>
            <Cart />
          </PrivateRoute>
        ),
      },
      // {
      //   path: "/product/:id",
      //   element: (
      //     <PrivateRoute>
      //       <SingleProduct/>
      //     </PrivateRoute>
      //   ),
      // },
      {
        path: "checkout",
        element: (
          <PrivateRoute>
            <Checkout/>
          </PrivateRoute>
        ),
      },
      {
        path: "order-success",
        element: (
          <PrivateRoute>
            <OrderSuccess/>
          </PrivateRoute>
        ),
      },
      {
        path: "order-history",
        element: (
          <PrivateRoute>
            <Orderhistory/>
          </PrivateRoute>
        ),
      },
      {
        path:"/order-details/:id",
        element: (
          <PrivateRoute>
            <OrderDetails/>
          </PrivateRoute>
        ),
      },
      
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Bounce}
        />
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
